import { useEffect, useRef } from "react"
import QrScanner from "qr-scanner"

import './css/VideoStream.css'
import { TbRubberStamp } from "react-icons/tb"

interface VideoStreamProps {
  deviceId: string | null,
  disableScanning: boolean,
  setScanResult: (result: string) => void
}

// enables the qr code scanner
function VideoStream(props: VideoStreamProps) {
  const {
    deviceId,
    disableScanning,
    setScanResult
  } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const scannerRef = useRef<QrScanner | null>(null)

  useEffect(() => {
    if (videoRef.current !== null && deviceId !== null && scannerRef.current === null) {
      try {
        scannerRef.current = new QrScanner(
          videoRef.current,
          result => {
            if (!disableScanning) {
              setScanResult(result.data.replace(/\"/g, ""))
            }

          },
          {
            onDecodeError: err => console.log(err),
            preferredCamera: deviceId,
            highlightScanRegion: false
          }
        )

        scannerRef.current.start()


      } catch (e) {
        console.log('eslint hates me')
      }

    }

    // clean up on unmount so we don't have a memory leak
    return () => {
      if (scannerRef.current !== null) {
        console.log('cleanup scanner')
        scannerRef.current = null
      }

      if (videoRef.current !== null) {
        console.log('cleanup video')
        videoRef.current.srcObject = null
      }
    }
  }, [deviceId, disableScanning])

  return (
    <video
      style={{ aspectRatio: "9/16", maxHeight: "60vh", objectFit: "cover" }}
      ref={videoRef}
    />
  )
}

export default VideoStream