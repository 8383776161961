// AttendeeList.tsx
/* eslint-disable */
import { useState } from 'react';
import { Registration, UpdateRegistrationInput, RegistrationStatusEnum } from './API';
import { UpdateRegistrationResponse, updateRegistrationDetails } from './mutations/processUpdateRegistration';
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Switch from "react-switch";
import { Link } from 'react-router-dom';

import './css/AttendeeList2.css';

interface AttendeeListProps {
  attendees: Registration[];
  setAttendees: React.Dispatch<React.SetStateAction<Registration[]>>;
}

function AttendeeList(props: AttendeeListProps) {
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [checkedInToggled, setCheckedInToggled] = useState<boolean>(false);
  const [testToggled, setTestToggled] = useState<boolean>(false);
  const [cancelledToggled, setCancelledToggled] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string>('ALL');

  const [selectedAttendees, setSelectedAttendees] = useState<Set<string>>(new Set());
  const [bulkAction, setBulkAction] = useState<string>('');
  const [rowActions, setRowActions] = useState<Record<string, string>>({});

  const handleSubmit = async (newStatus: RegistrationStatusEnum, attendee: Registration) => {
    // if (newStatus == "RESEND_EMAIL") {
    //   console.log("RESEND_EMAIL");
    //   alert("Emailed Ticket to registrant email")
    // }
    const registrationData: UpdateRegistrationInput = {
      eventId: attendee.eventId,
      registrationId: attendee.registrationId,
      registrationStatus: newStatus,
    };

    try {
      const response = await updateRegistrationDetails(registrationData);
      console.log('Response from updateRegistrationDetails:', response);

      // Check if the response is successful and update the state
      if (response && response.registrationStatus === newStatus) {
        // Update the attendees state to reflect the new status
        props.setAttendees(prevAttendees =>
          prevAttendees.map(a =>
            a.registrationId === attendee.registrationId ? { ...a, registrationStatus: newStatus } : a
          )
        );
        // Reset the row action to "Select Action..."
        setRowActions(prevRowActions => ({
          ...prevRowActions,
          [attendee.registrationId]: '',
        }));
      } else {
        console.error('Unexpected response structure or status mismatch:', response);
      }
      setActiveDropdownId(null);
    } catch (err) {
      console.error('Error updating registration:', err);
      setActiveDropdownId(null);
    }
  };


  // Toggle dropdown display based on registrationId
  const toggleDropdown = (registrationId: string) => {
    setActiveDropdownId(activeDropdownId === registrationId ? null : registrationId);
  };

  const handleTestToggleChange = (checked: boolean) => {
    setTestToggled(checked);
  }

  const toggleSelectAll = (checked: boolean) => {
    if (checked) {
      const allIds = props.attendees.map(attendee => attendee.registrationId);
      setSelectedAttendees(new Set(allIds));
    } else {
      setSelectedAttendees(new Set());
    }
  };

  // Function to handle toggling the selection of an individual attendee
  const toggleSelectOne = (id: string) => {
    const newSet = new Set(selectedAttendees);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setSelectedAttendees(newSet);
  };

  // Function to handle executing the selected action on all selected attendees
  const handleSubmitAction = async () => {
    // Convert the selectedAttendees set to an array to iterate over it
    const attendeesArray = Array.from(selectedAttendees);

    if (bulkAction === "MARK_AS_ATTENDED") {
      // Loop through the array and update each attendee's status to CHECKED_IN
      for (const id of attendeesArray) {
        const attendee = props.attendees.find(a => a.registrationId === id);
        if (attendee) {
          await handleSubmit(RegistrationStatusEnum.CHECKED_IN, attendee);
        }
      }
    } else if (bulkAction === "MARK_AS_UNATTENDED") {
      // Loop through the array and update each attendee's status to REGISTERED
      for (const id of attendeesArray) {
        const attendee = props.attendees.find(a => a.registrationId === id);
        if (attendee) {
          await handleSubmit(RegistrationStatusEnum.REGISTERED, attendee);
        }
      }
    } else if (bulkAction === "COMMIT_TO_CRM") {
      // Display an alert to indicate submission to CRM
      alert("Data upload successful. Selected attendees have been marked to be submitted to CRM by end of day.");

      // Set the submittedToCRM flag for the selected attendees
      props.setAttendees(prevAttendees =>
        prevAttendees.map(attendee => {
          if (selectedAttendees.has(attendee.registrationId)) {
            return { ...attendee, submittedToCRM: true };
          }
          return attendee;
        })
      );
    }

    // Log the action taken for debugging purposes
    console.log(`Action ${bulkAction} applied to selected attendees:`, attendeesArray);
  };


  const sortedAndFilteredAttendees = props.attendees
    .sort((a, b) => {
      const lastNameA = a.lastName ? a.lastName.toLowerCase() : '';
      const lastNameB = b.lastName ? b.lastName.toLowerCase() : '';
      if (lastNameA < lastNameB) return -1;
      if (lastNameA > lastNameB) return 1;
      return 0;
    })
    .filter(attendee => {
      const query = searchQuery.toLowerCase();
      const firstName = attendee.firstName ? attendee.firstName.toLowerCase() : '';
      const lastName = attendee.lastName ? attendee.lastName.toLowerCase() : '';
      const email = attendee.email ? attendee.email.toLowerCase() : '';
      const fullName = `${firstName} ${lastName}`;

      const matchesQuery = fullName.includes(query) || email.includes(query);
      const isNotCheckedInWhenToggled = !checkedInToggled || attendee.registrationStatus !== "CHECKED_IN";
      const isNotCancelledWhenToggled = !cancelledToggled || attendee.registrationStatus !== "CANCELLED";
      const showTestRegistration = testToggled || attendee.live;
      const matchesStatus = statusFilter === 'ALL' || attendee.registrationStatus === statusFilter;

      return matchesQuery && isNotCheckedInWhenToggled && isNotCancelledWhenToggled && showTestRegistration && matchesStatus;
    });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return date.toLocaleString(undefined, options);
  };


  return (
    <>
      <div className='registrations-options-container'>
        <h2 className="registrations-options-header">Registrants</h2>
        <div className='registrations-status-container'>
          <p className='registrations-status-header-text'>CHECKED IN:</p>
          <p className='registrations-status-value-text'>
            {sortedAndFilteredAttendees.filter((reg) => reg.registrationStatus === "CHECKED_IN").length}/{sortedAndFilteredAttendees.length}
          </p>
        </div>
      </div>

      <div className='attendee-list-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h4 style={{ marginTop: '0rem', marginBottom: '0rem' }} className="attendee-header">Filter by Status</h4>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="status-filter-dropdown"
          >
            <option value="ALL">All</option>
            <option value="CHECKED_IN">Checked In</option>
            <option value="REGISTERED">Registered</option>
            <option value="CANCELLED">Cancelled</option>
          </select>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h4 style={{ marginTop: '0rem', marginBottom: '0rem' }} className="attendee-header">Show Test Registrations</h4>
          <Switch onChange={handleTestToggleChange} checked={testToggled} />
        </div>

        <input
          type="text"
          className="search-box"
          placeholder="Search by name or email..."
          onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery based on user input
        />
        {/* TODO remove */}
        {/* <p style={{fontSize:12}}>* Data submitted for processing to CRM. </p> */}


        <div className=''>
          <div className='actions-dropdown'>
            <select value={bulkAction} onChange={e => setBulkAction(e.target.value)}>
              <option value="" >Select Action...</option>
              <option value="COMMIT_TO_CRM">Submit Data</option>
              <option value="MARK_AS_ATTENDED">Check In</option>
              <option value="MARK_AS_UNATTENDED">Undo Check In</option>
            </select>
            <button onClick={handleSubmitAction}>Submit</button>
          </div>

          <br />

          <table className="attendee-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={e => toggleSelectAll(e.target.checked)}
                    checked={selectedAttendees.size === props.attendees.length}
                  />
                </th>
                <th>Name</th>
                <th>Email</th>
                <th className='hide-for-mobile'>Transaction date</th>
                <th>Status</th>
                <th className='hide-for-mobile'>Guests</th>
                <th className='hide-for-mobile'>Actions</th>
                <th className='hide-for-desktop'>Check-In</th>
                <th className='hide-for-desktop'>More</th>
              </tr>
            </thead>

            <tbody>
              {sortedAndFilteredAttendees.map(attendee => (
                <tr key={attendee.registrationId} className="attendee-row">
                  {/* className={`attendee-row ${attendee.submittedToCRM ? 'submitted-to-crm' : ''}`}> */}
                  <td>
                    <div className='input-row'>
                      <input
                        type='checkbox'
                        className='input-checkbox'
                        checked={selectedAttendees.has(attendee.registrationId)}
                        onChange={() => toggleSelectOne(attendee.registrationId)}
                      />
                    </div>
                  </td>
                  <td>
                    {attendee.submissionId ? (
                      <Link to={`https://reports.support2.ucla.edu/reports/datacapture/Report.aspx?Test=Y&Indiv=Y&SubmissionID=${attendee.submissionId}`}>
                        {attendee.firstName} {attendee.lastName}{!attendee.live && <b> - (TEST)</b>}
                      </Link>
                    ) : (
                      <>
                        {attendee.firstName} {attendee.lastName}{!attendee.live && <b> - (TEST)</b>}
                      </>
                    )}
                  </td>
                  <td>{attendee.email}</td>
                  <td>{formatDate(attendee.dateCreated)}</td>
                  <td>
                    <span className={`attendee-status-icon ${attendee.registrationStatus}`} />
                    {attendee.registrationStatus}
                  </td>
                  <td>{(attendee.guests && attendee.guests > 0) && `${attendee.guests} guests`}</td>
                  <td className='hide-for-mobile'>
                    <div className='actions-dropdown'>
                      <select
                        value={rowActions[attendee.registrationId] || null}
                        onChange={(e) => {
                          const newActions = { ...rowActions, [attendee.registrationId]: e.target.value };
                          setRowActions(newActions);
                        }}
                      >
                        <option value="">Select Action...</option>
                        <option value={RegistrationStatusEnum.CHECKED_IN}>Check In</option>
                        <option value={RegistrationStatusEnum.REGISTERED}>Undo Check In</option>
                        {/* <option value={RegistrationStatusEnum.CANCELLED}>Cancel Registration</option> */}
                        {/* <option value="RESEND_EMAIL">Email Ticket</option> */}
                      </select>
                      <button onClick={() => handleSubmit(rowActions[attendee.registrationId] as RegistrationStatusEnum, attendee)}>Run</button>
                    </div>
                  </td>
                  <td className='hide-for-desktop'>
                    <div className='button-container'>
                      {attendee.registrationStatus === RegistrationStatusEnum.CHECKED_IN ? (
                        <button
                          className='check-in-btn check-out'
                          onClick={() => handleSubmit(RegistrationStatusEnum.REGISTERED, attendee)}
                        >
                          Undo Check In
                        </button>
                      ) : (
                        <button
                          className='check-in-btn'
                          onClick={() => handleSubmit(RegistrationStatusEnum.CHECKED_IN, attendee)}
                        >
                          Check In
                        </button>
                      )}
                    </div>
                  </td>
                  <td className='hide-for-desktop'>
                    <div className='button-container'>
                      {/* <button className='more-btn' onClick={() => toggleDropdown(attendee.registrationId)}>More</button> */}
                      {activeDropdownId === attendee.registrationId && (
                        <div>
                          {/* <button className="more-btn" onClick={() => handleSubmit("RESEND_EMAIL", attendee)}>Resend Event Ticket</button> */}
                          {/* <button className="more-btn" onClick={() => handleSubmit(RegistrationStatusEnum.CANCELLED, attendee)}>Cancel Registration</button> */}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
    </>
  );
}

export default AttendeeList;
