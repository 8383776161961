// src/main.tsx
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import client from './apolloClient';
import KeyCloakService from './security/keycloakService';
import buildMetadata from './build-metadata.json';

const GRAPHQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_ENDPOINT as string;
const GRAPHQL_REGION = import.meta.env.VITE_GRAPHQL_REGION as string;
const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string;
const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM as string;
const KEYCLOAK_CLIENT = import.meta.env.VITE_KEYCLOAK_CLIENT as string;

const initApp = async () => {
  console.log('renderApp -- main.tsx');

  console.log("build environment: " + buildMetadata.build_environment);
  console.log("build date: " + buildMetadata.date);
  console.log("build ID: " + buildMetadata.build_id);
  console.log("github commit ID: " + buildMetadata.commit_id);
  console.log("graphql endpoint: " + GRAPHQL_ENDPOINT);
  console.log("graphql region: " + GRAPHQL_REGION);
  console.log("keycloak url: " + KEYCLOAK_URL);
  console.log("keycloak realm: " + KEYCLOAK_REALM);
  console.log("keycloak client: " + KEYCLOAK_CLIENT);

  try {
    await KeyCloakService.CallLogin(() => {
      ReactDOM.render(
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>,
        document.getElementById('root')
      );
    });
  } catch (err) {
    console.error('Failed to initialize Keycloak:', err);
  }
};

// Initialize Keycloak and render the app - void promise
void initApp();