import { FormEvent, useEffect, useState } from "react"
interface CameraSelectProps {
  changeInputStream: (deviceId: string | null) => void
}

// create a select dropdown that lists the available devices
function CameraSelect( props: CameraSelectProps ) {
  const { changeInputStream } = props
  const [ options, setOptions ] = useState<Array<MediaDeviceInfo>>([])
  const [ selectedOption, setSelected ] = useState("none")
  const [ message, setMessage ] = useState("Loading media devices...")

  function selectCamera(e: FormEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget
    
    setSelected(value)
    if (value === "none") {
      changeInputStream(null)
    } else {
      changeInputStream(value)
    }
    
  }

  // load the select with available devices
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(
      devices => {
        console.log('cameras:', devices)
        const videodevices: Array<MediaDeviceInfo> = [] 
        for (const device of devices) {
          if (device.kind.includes('video')) {
            videodevices.push(device)
          }
        }
        if (videodevices.length) {
          setMessage("Please select a camera from the list below:")
          setOptions(videodevices)
        }
        
      }
    ).catch(err => {
      console.log('enumeration failed', err)
    })
  }, [])


  

  return (
    <select 
      name="cameraSelect" 
      onChange={selectCamera} 
      defaultValue={selectedOption}
      className="camera-select"
    >
      <option key="default" value="none">{ message }</option>
      { options?.map( (option: MediaDeviceInfo, idx ) => <option key={idx} value={option.deviceId}>{ option.label || option.kind }</option> )}
    </select>
  )
}

export default CameraSelect