import { ReactNode } from 'react'
import './css/ScanResult.css'

interface ScanResultProps {
  children: ReactNode,
  isValid: boolean
}

function ScanResult( props: ScanResultProps ) {
  const {
    children,
    isValid
  } = props

  return children? (
    <div className="result-overlay">
      <div 
        className={`scan-result${isValid? " is-valid" : "" }`}
      >
        { children }
      </div>
    </div>
  ) : null

}

export default ScanResult