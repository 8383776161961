import { gql } from '@apollo/client';
import client from '../apolloClient';
import { createEventUser } from '../graphql/mutations';
import { CreateEventUserInput } from '../API';

export type CreateEventUserResponse = {
    createEventUser: {
        eventId: string;
        version: string;
        sourceSiteId: string;
        sourceName: string;
        eventName: string;
        ownerEmail: string;
        ownerFirstName: string;
        ownerLastName: string;
        crmId?: string | null;
        live?: boolean | null;
        date?: string | null;
        endDate?: string | null;
        startTime?: string | null;
        endTime?: string | null;
        locale?: string | null;
        location?: string | null;
        room?: string | null;
        address?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        description?: string | null;
        calendarStatus?: string | null;
        department?: string | null;
        marketingEffort?: string | null;
        invitationMarketingEffort?: string | null;
        additionalData?: string | null;
        eventRole?: string | null;
    };
};

export async function addUserToEvent(input: CreateEventUserInput): Promise<CreateEventUserResponse> {
    console.log('addEventUser input: ', input);
    try {
        const { data } = await client.mutate<{ createEventUser: CreateEventUserResponse }>({
            mutation: gql`
                ${createEventUser}
            `,
            variables: { input },
        });
        if (!data) {
            throw new Error('No data returned from mutation');
        }
        return data.createEventUser;
    } catch (err) {
        console.error('Error creating event user:', err);
        throw err;
    }
}