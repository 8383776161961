import { Link } from 'react-router-dom'
import { Event } from './API'

import './css/EventList.css'

interface EventListItemProps {
  event: Event,
}

function EventListItem( props: EventListItemProps ) {
  const { event } = props
  const {
    eventId,
    eventName,
    location,
    date
  } = event

  const readableDate = new Date(`${date}T00:00`).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  return (
    <Link to={`${eventId}`} className="event-list-item">
      <div className="event-information">
        <h2 className="no-margin">{ eventName } </h2>
        <br/>
        <address>
          { location }
        </address>
        <div className="event-date">{ date }</div>
      </div>
      <div className="event-view-details">View Details</div>
    </Link>
  )
}

export default EventListItem