/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateEventInput = {
  sourceSiteId: string,
  sourceName: string,
  eventName: string,
  ownerEmail: string,
  eventKey?: string | null,
  ownerFirstName?: string | null,
  ownerLastName?: string | null,
  crmId?: string | null,
  live?: boolean | null,
  date?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  locale?: string | null,
  location?: string | null,
  room?: string | null,
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: string | null,
  description?: string | null,
  calendarStatus?: EventStatusEnum | null,
  department?: string | null,
  marketingEffort?: string | null,
  invitationMarketingEffort?: string | null,
  additionalData?: string | null,
};

export enum EventStatusEnum {
  UNPUBLISHED = "UNPUBLISHED",
  PUBLISHED = "PUBLISHED",
  CANCELLED = "CANCELLED",
  DELETED = "DELETED",
}


export type Event = {
  __typename: "Event",
  eventId: string,
  version: string,
  sourceSiteId: string,
  sourceName: string,
  eventName: string,
  ownerEmail: string,
  eventKey?: string | null,
  ownerFirstName?: string | null,
  ownerLastName?: string | null,
  crmId?: string | null,
  live?: boolean | null,
  date?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  locale?: string | null,
  location?: string | null,
  room?: string | null,
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: string | null,
  description?: string | null,
  calendarStatus?: EventStatusEnum | null,
  department?: string | null,
  marketingEffort?: string | null,
  invitationMarketingEffort?: string | null,
  additionalData?: string | null,
  eventRole?: EventRoleEnum | null,
};

export enum EventRoleEnum {
  OWNER = "OWNER",
  COOWNER = "COOWNER",
  TEAM = "TEAM",
}


export type CreateEventUserInput = {
  eventRole: EventRoleEnum,
  eventId: string,
  ownerEmail: string,
  eventKey?: string | null,
  sourceSiteId?: string | null,
  sourceName?: string | null,
  eventName?: string | null,
  ownerFirstName?: string | null,
  ownerLastName?: string | null,
  crmId?: string | null,
  live?: boolean | null,
  date?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  locale?: string | null,
  location?: string | null,
  room?: string | null,
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: string | null,
  description?: string | null,
  calendarStatus?: EventStatusEnum | null,
  department?: string | null,
  marketingEffort?: string | null,
  invitationMarketingEffort?: string | null,
  additionalData?: string | null,
};

export type UpdateEventInput = {
  eventId: string,
  sourceSiteId?: string | null,
  eventName?: string | null,
  ownerEmail?: string | null,
  ownerFirstName?: string | null,
  ownerLastName?: string | null,
  crmId?: string | null,
  live?: boolean | null,
  date?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  locale?: string | null,
  location?: string | null,
  room?: string | null,
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: string | null,
  description?: string | null,
  calendarStatus?: EventStatusEnum | null,
  department?: string | null,
  marketingEffort?: string | null,
  invitationMarketingEffort?: string | null,
  additionalData?: string | null,
};

export type DeleteEventInput = {
  eventId: string,
};

export type DeleteUserInput = {
  eventId: string,
  ownerEmail: string,
};

export type CreateRegistrationInput = {
  eventId: string,
  parentEventKey?: string | null,
  email: string,
  firstName: string,
  lastName: string,
  crmId?: string | null,
  registrationStatus?: RegistrationStatusEnum | null,
  live?: boolean | null,
  guests?: number | null,
  additionalData?: string | null,
  submissionId?: string | null,
};

export enum RegistrationStatusEnum {
  REGISTERED = "REGISTERED",
  CHECKED_IN = "CHECKED_IN",
  CANCELLED = "CANCELLED",
  WALK_IN = "WALK_IN",
  DELETED = "DELETED",
}


export type Registration = {
  __typename: "Registration",
  registrationId: string,
  eventId: string,
  parentEventKey?: string | null,
  email?: string | null,
  crmId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  registrationStatus?: RegistrationStatusEnum | null,
  dateCreated?: string | null,
  qrBase64?: string | null,
  pdfBase64?: string | null,
  pdfUrl?: string | null,
  live?: boolean | null,
  guests?: number | null,
  additionalData?: string | null,
  submissionId?: string | null,
};

export type UpdateRegistrationInput = {
  eventId: string,
  registrationId: string,
  email?: string | null,
  crmId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  registrationStatus?: RegistrationStatusEnum | null,
  guests?: number | null,
  submissionId?: string | null,
};

export type DeleteRegistrationInput = {
  eventId: string,
  registrationId: string,
};

export type TableEventFilterInput = {
  eventId?: TableStringFilterInput | null,
  sourceSiteId?: TableStringFilterInput | null,
  sourceName?: TableStringFilterInput | null,
  eventName?: TableStringFilterInput | null,
  location?: TableStringFilterInput | null,
  calendarStatus?: TableStringFilterInput | null,
  date?: TableStringFilterInput | null,
  ownerEmail?: TableStringFilterInput | null,
  crmId?: TableStringFilterInput | null,
};

export type TableStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type EventConnection = {
  __typename: "EventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type TableRegistrationFilterInput = {
  eventId?: TableStringFilterInput | null,
  registrationId?: TableStringFilterInput | null,
  email?: TableStringFilterInput | null,
  firstName?: TableStringFilterInput | null,
  lastName?: TableStringFilterInput | null,
  crmId?: TableStringFilterInput | null,
  registrationStatus?: TableStringFilterInput | null,
};

export type RegistrationConnection = {
  __typename: "RegistrationConnection",
  items?:  Array<Registration | null > | null,
  nextToken?: string | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type CreateEventUserMutationVariables = {
  input: CreateEventUserInput,
};

export type CreateEventUserMutation = {
  createEventUser?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type CreateRegistrationMutationVariables = {
  input: CreateRegistrationInput,
};

export type CreateRegistrationMutation = {
  createRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};

export type UpdateRegistrationMutationVariables = {
  input: UpdateRegistrationInput,
};

export type UpdateRegistrationMutation = {
  updateRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};

export type DeleteRegistrationMutationVariables = {
  input: DeleteRegistrationInput,
};

export type DeleteRegistrationMutation = {
  deleteRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  eventId: string,
  version: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: TableEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "EventConnection",
    items?:  Array< {
      __typename: "Event",
      eventId: string,
      version: string,
      sourceSiteId: string,
      sourceName: string,
      eventName: string,
      ownerEmail: string,
      eventKey?: string | null,
      ownerFirstName?: string | null,
      ownerLastName?: string | null,
      crmId?: string | null,
      live?: boolean | null,
      date?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      locale?: string | null,
      location?: string | null,
      room?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: string | null,
      description?: string | null,
      calendarStatus?: EventStatusEnum | null,
      department?: string | null,
      marketingEffort?: string | null,
      invitationMarketingEffort?: string | null,
      additionalData?: string | null,
      eventRole?: EventRoleEnum | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByEmailQueryVariables = {
  ownerEmail: string,
};

export type ListEventsByEmailQuery = {
  listEventsByEmail?:  Array< {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null > | null,
};

export type GetRegistrationQueryVariables = {
  registrationId: string,
  eventId: string,
};

export type GetRegistrationQuery = {
  getRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};

export type ListRegistrationsQueryVariables = {
  filter?: TableRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationsQuery = {
  listRegistrations?:  {
    __typename: "RegistrationConnection",
    items?:  Array< {
      __typename: "Registration",
      registrationId: string,
      eventId: string,
      parentEventKey?: string | null,
      email?: string | null,
      crmId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      registrationStatus?: RegistrationStatusEnum | null,
      dateCreated?: string | null,
      qrBase64?: string | null,
      pdfBase64?: string | null,
      pdfUrl?: string | null,
      live?: boolean | null,
      guests?: number | null,
      additionalData?: string | null,
      submissionId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByCalendarStatusQueryVariables = {
  calendarStatus: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByCalendarStatusQuery = {
  listEventsByCalendarStatus?:  {
    __typename: "EventConnection",
    items?:  Array< {
      __typename: "Event",
      eventId: string,
      version: string,
      sourceSiteId: string,
      sourceName: string,
      eventName: string,
      ownerEmail: string,
      eventKey?: string | null,
      ownerFirstName?: string | null,
      ownerLastName?: string | null,
      crmId?: string | null,
      live?: boolean | null,
      date?: string | null,
      endDate?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      locale?: string | null,
      location?: string | null,
      room?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: string | null,
      description?: string | null,
      calendarStatus?: EventStatusEnum | null,
      department?: string | null,
      marketingEffort?: string | null,
      invitationMarketingEffort?: string | null,
      additionalData?: string | null,
      eventRole?: EventRoleEnum | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRegistrationsByEventIdQueryVariables = {
  eventId: string,
};

export type ListRegistrationsByEventIdQuery = {
  listRegistrationsByEventId?:  Array< {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null > | null,
};

export type ListRegistrationsByEmailQueryVariables = {
  email: string,
};

export type ListRegistrationsByEmailQuery = {
  listRegistrationsByEmail?:  Array< {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null > | null,
};

export type OnCreateEventSubscriptionVariables = {
  eventId?: string | null,
  sourceSiteId?: string | null,
  sourceName?: string | null,
  eventName?: string | null,
  location?: string | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  eventId?: string | null,
  sourceSiteId?: string | null,
  sourceName?: string | null,
  eventName?: string | null,
  location?: string | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  eventId?: string | null,
  sourceSiteId?: string | null,
  sourceName?: string | null,
  eventName?: string | null,
  location?: string | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    eventId: string,
    version: string,
    sourceSiteId: string,
    sourceName: string,
    eventName: string,
    ownerEmail: string,
    eventKey?: string | null,
    ownerFirstName?: string | null,
    ownerLastName?: string | null,
    crmId?: string | null,
    live?: boolean | null,
    date?: string | null,
    endDate?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    locale?: string | null,
    location?: string | null,
    room?: string | null,
    address?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: string | null,
    description?: string | null,
    calendarStatus?: EventStatusEnum | null,
    department?: string | null,
    marketingEffort?: string | null,
    invitationMarketingEffort?: string | null,
    additionalData?: string | null,
    eventRole?: EventRoleEnum | null,
  } | null,
};

export type OnCreateRegistrationSubscriptionVariables = {
  eventId?: string | null,
  registrationId?: string | null,
};

export type OnCreateRegistrationSubscription = {
  onCreateRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};

export type OnUpdateRegistrationSubscriptionVariables = {
  eventId?: string | null,
  registrationId?: string | null,
};

export type OnUpdateRegistrationSubscription = {
  onUpdateRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};

export type OnDeleteRegistrationSubscriptionVariables = {
  eventId?: string | null,
  registrationId?: string | null,
};

export type OnDeleteRegistrationSubscription = {
  onDeleteRegistration?:  {
    __typename: "Registration",
    registrationId: string,
    eventId: string,
    parentEventKey?: string | null,
    email?: string | null,
    crmId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    registrationStatus?: RegistrationStatusEnum | null,
    dateCreated?: string | null,
    qrBase64?: string | null,
    pdfBase64?: string | null,
    pdfUrl?: string | null,
    live?: boolean | null,
    guests?: number | null,
    additionalData?: string | null,
    submissionId?: string | null,
  } | null,
};
