// src/apolloClient.tsx
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const GRAPHQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_ENDPOINT as string;

if (!GRAPHQL_ENDPOINT) {
  throw new Error('Missing environment variables for GraphQL endpoint');
}

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT
});

interface Headers {
  [key: string]: string;
}


// Set the authorization header with the token from KeyCloakService
const authLink = setContext((_, { headers }: { headers?: Headers }) => {
  const token = localStorage.getItem('kc_idToken');
  if (!token) {
    console.error('No token found!');
    // TODO display UI error 
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

// the Apollo Client
const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;