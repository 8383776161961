/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEvent = /* GraphQL */ `query GetEvent($eventId: String!, $version: String!) {
  getEvent(eventId: $eventId, version: $version) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: TableEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      eventId
      version
      sourceSiteId
      sourceName
      eventName
      ownerEmail
      eventKey
      ownerFirstName
      ownerLastName
      crmId
      live
      date
      endDate
      startTime
      endTime
      locale
      location
      room
      address
      address2
      city
      state
      zipCode
      description
      calendarStatus
      department
      marketingEffort
      invitationMarketingEffort
      additionalData
      eventRole
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const listEventsByEmail = /* GraphQL */ `query ListEventsByEmail($ownerEmail: String!) {
  listEventsByEmail(ownerEmail: $ownerEmail) {
    eventId
    version
    sourceSiteId
    sourceName
    eventName
    ownerEmail
    eventKey
    ownerFirstName
    ownerLastName
    crmId
    live
    date
    endDate
    startTime
    endTime
    locale
    location
    room
    address
    address2
    city
    state
    zipCode
    description
    calendarStatus
    department
    marketingEffort
    invitationMarketingEffort
    additionalData
    eventRole
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByEmailQueryVariables,
  APITypes.ListEventsByEmailQuery
>;
export const getRegistration = /* GraphQL */ `query GetRegistration($registrationId: String!, $eventId: String!) {
  getRegistration(registrationId: $registrationId, eventId: $eventId) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRegistrationQueryVariables,
  APITypes.GetRegistrationQuery
>;
export const listRegistrations = /* GraphQL */ `query ListRegistrations(
  $filter: TableRegistrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listRegistrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      registrationId
      eventId
      parentEventKey
      email
      crmId
      firstName
      lastName
      registrationStatus
      dateCreated
      qrBase64
      pdfBase64
      pdfUrl
      live
      guests
      additionalData
      submissionId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRegistrationsQueryVariables,
  APITypes.ListRegistrationsQuery
>;
export const listEventsByCalendarStatus = /* GraphQL */ `query ListEventsByCalendarStatus(
  $calendarStatus: String!
  $limit: Int
  $nextToken: String
) {
  listEventsByCalendarStatus(
    calendarStatus: $calendarStatus
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      eventId
      version
      sourceSiteId
      sourceName
      eventName
      ownerEmail
      eventKey
      ownerFirstName
      ownerLastName
      crmId
      live
      date
      endDate
      startTime
      endTime
      locale
      location
      room
      address
      address2
      city
      state
      zipCode
      description
      calendarStatus
      department
      marketingEffort
      invitationMarketingEffort
      additionalData
      eventRole
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByCalendarStatusQueryVariables,
  APITypes.ListEventsByCalendarStatusQuery
>;
export const listRegistrationsByEventId = /* GraphQL */ `query ListRegistrationsByEventId($eventId: String!) {
  listRegistrationsByEventId(eventId: $eventId) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRegistrationsByEventIdQueryVariables,
  APITypes.ListRegistrationsByEventIdQuery
>;
export const listRegistrationsByEmail = /* GraphQL */ `query ListRegistrationsByEmail($email: String!) {
  listRegistrationsByEmail(email: $email) {
    registrationId
    eventId
    parentEventKey
    email
    crmId
    firstName
    lastName
    registrationStatus
    dateCreated
    qrBase64
    pdfBase64
    pdfUrl
    live
    guests
    additionalData
    submissionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRegistrationsByEmailQueryVariables,
  APITypes.ListRegistrationsByEmailQuery
>;
