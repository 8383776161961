// src/NavBar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa"; // Import the plus icon

interface NavBarProps {
  eventName: string;
  eventId: string;
}

const NavBar: React.FC<NavBarProps> = ({ eventName, eventId }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Link className="nav-link" to="/">All Events / </Link>
      <Link className="nav-link" to=''> {' '}{eventName} </Link>
      <Link
        className='nav-link'
        to={`/${eventId}/addUser`}
        style={{ marginLeft: 'auto' }}
        state={{ eventName }} // Pass eventName as state
      >
      </Link>
    </div>
  );
};

export default NavBar;