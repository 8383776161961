import { useEffect, useState } from 'react'
import { Registration, UpdateRegistrationInput, RegistrationStatusEnum } from './API'
import { updateRegistrationDetails } from './mutations/processUpdateRegistration'
import CameraSelect from "./CameraSelect"
import VideoStream from './VideoStream'
import ScanResult from './ScanResult'

import './css/ScanResult.css'

interface ScanTicketProps {
  eventId: string
  attendees: Registration[]
  handler: (result: Registration | null) => void
  goBack: () => void
  setAttendees: React.Dispatch<React.SetStateAction<Registration[]>>;
}

function ScanTicket(props: ScanTicketProps) {
  const {
    handler,
    goBack,
    attendees
  } = props
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const [hasScanned, setHasScanned] = useState<boolean>(false)
  const [validAttendee, setValidAttendee] = useState<Registration | null>(null)
  const [message, setMessage] = useState("Checking Access...")
  const [devicesAvailable, setDevicesAvailable] = useState<boolean>(false)

  function handleScanResult(qrString: string) {
    // interpret the URL string encoded in the QR ticket - use this format:
    // https://<domain>/ticket/<eventId>/<registrationId>
    // const [ eventId, registrationId] = qrString.split('/').slice(-2)
    // const found = (props.eventId === eventId) && attendees.find( attendee => registrationId === attendee.registrationId )


    // interpret the URL string encoded in QR ticket - current format:
    // https://checkin.support2.ucla.edu/ticket?q=eventId:registrationId
    setHasScanned(true)

    const url = new URL(qrString);
    const qParam = url.searchParams.get('q');
    let qrEventId = "";
    let qrRegistrationId = "";
    if (qParam) {
      [qrEventId, qrRegistrationId] = qParam.split(':');
    }
    const found = (props.eventId === qrEventId) && attendees.find(attendee => qrRegistrationId === attendee.registrationId);
    console.log("found: ", found)

    if (found && found.registrationStatus !== "REGISTERED") {
      let message = "";
      switch (found.registrationStatus) {
        case "CANCELLED":
          message = "Ticket has been Cancelled";
          break;
        case "CHECKED_IN":
          message = "Ticket has already been used to Check In";
          break;
        default:
          message = "Invalid ticket";
          break;
      }
      setMessage(message);
      setValidAttendee(null);
    } else if (found) {
      setValidAttendee(found || null)
      setMessage('');
    }

  }

  async function handleAcknowledge() {
    if (!validAttendee) return; // Guard clause to ensure validAttendee is defined

    const registrationData: UpdateRegistrationInput = {
      eventId: validAttendee.eventId,
      registrationId: validAttendee.registrationId,
      registrationStatus: RegistrationStatusEnum.CHECKED_IN,
    };

    try {
      await updateRegistrationDetails(registrationData);
      // Update the local state of attendees in the parent component
      props.setAttendees((prevAttendees) =>
        prevAttendees.map((attendee) =>
          attendee.registrationId === validAttendee.registrationId ? { ...attendee, registrationStatus: RegistrationStatusEnum.CHECKED_IN } : attendee
        )
      );
      setHasScanned(false);
      handler(validAttendee); // Assuming this is to handle post-check-in logic
    } catch (err) {
      console.log("Encountered an error updating status.", err);
      setMessage("Encountered an error updating status.");
      setHasScanned(false); // Consider if you want to reset scanning state here or only upon successful check-in
    }
  }


  function scanMore() {
    setHasScanned(false);
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({
      video: true
    }).then(() => {
      setDevicesAvailable(true);

    }).catch((err: Error) => {
      // handle the error
      console.log(err)
      if (typeof navigator.mediaDevices === 'undefined') {
        setMessage("Unable to access media, check SSL settings.")
      } else if (err.name === "NotAllowedError") {
        setMessage("Please grant this page permission to access your camera.")
      } else if (err.name === "OverconstrainedError") {
        setMessage("Resolution is not supported by your device.")
      } else {
        setMessage("An unknown error has occurred.")
      }

    })
  }, [])

  function capitalizeFirstLetter(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return devicesAvailable ? (
    <div className="scan-container">
      <div className="video-container">
        <VideoStream
          deviceId={deviceId}
          setScanResult={handleScanResult}
          disableScanning={hasScanned}
        />
        {hasScanned && (
          <ScanResult isValid={validAttendee ? true : false}>
            {validAttendee && (
              <div className='scan-result-inner-container'>
                <b>
                  Registrant:
                  <br />
                  {capitalizeFirstLetter(validAttendee.firstName)} {capitalizeFirstLetter(validAttendee.lastName)}
                </b>
                <br />
                <br />
                {validAttendee.live == false && "TEST REGISTRATION"}
                {/* Additional Guests - {validAttendee.guests} */}
              </div>
            )}
            {!validAttendee && (<div><b>Invalid Ticket</b><br />{message}</div>)}
            <div className='controls'>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <button className='checkin-button' disabled={!validAttendee} onClick={handleAcknowledge}>Check In</button>
              <button onClick={scanMore}>Cancel</button>
            </div>
          </ScanResult>
        )}
      </div>


      <div className="video-controls">
        <CameraSelect changeInputStream={setDeviceId} />
        <button className='event-button' onClick={goBack}>Back to Event Details</button>
      </div>
    </div>
  ) : (
    <p>{message}</p>
  )
}

export default ScanTicket
