// src/loaders.tsx
import { gql } from '@apollo/client';
import client from './apolloClient';
import { LoaderFunctionArgs } from 'react-router-dom';
import { listEventsByEmail, listRegistrationsByEventId } from './graphql/queries';
import { Event, Registration } from './API';
import { mockEvents, mockRegistrations } from './mockData';

const isDev = process.env.NODE_ENV === 'development';

interface ListRegistrationsResponse {
  listRegistrations: {
    items: Registration[];
    nextToken: string | null;
    __typename: string;
  };
}

// Event loader
export async function eventLoader() {
  if (isDev) {
    console.log('Using mock events data for development.');
    return mockEvents;
  }

  const ownerEmail = localStorage.getItem('kc_email');
  if (!ownerEmail) {
    console.error('No ownerEmail found in localStorage');
    return null;
  }

  try {
    const { data } = await client.query<{ listEventsByEmail: Event[] }>({
      query: gql`${listEventsByEmail}`,
      variables: { ownerEmail },
    });

    if (data && data.listEventsByEmail) {
      return data.listEventsByEmail;
    } else {
      console.error('No data received');
      return null;
    }
  } catch (err) {
    console.error('Error loading events:', err);
    return null;
  }
}

// Registrations loader
export async function registrationsLoader({ params }: LoaderFunctionArgs) {
  if (isDev) {
    console.log('Using mock registrations data for development.');
    return mockRegistrations;
  }

  const eventId = params.eventId;
  if (!eventId) {
    console.error('No eventId found in params');
    return null;
  }

  try {
    const { data } = await client.query<{ listRegistrationsByEventId: Registration[]}>({
      query: gql`${listRegistrationsByEventId}`,
      variables: { eventId },
    });

    if (data && data.listRegistrationsByEventId) {
      return data.listRegistrationsByEventId;
    } else {
      console.error('No data received');
      return null;
    }
  } catch (err) {
    console.error('Error loading registrations:', err);
    return null;
  }
}