import { Outlet } from 'react-router-dom'
import Banner from './Banner.tsx'

export default function Layout() {
  
  return (
    <div className="wrapper">
      <Banner />
      <Outlet />
    </div>
  )
}