import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import QRCode from 'react-qr-code';

interface EventQrProps {
    event: {
        eventId: string;
        eventName: string;
        location?: string;
        date?: string;
        description?: string;
    };
    setViewQr: React.Dispatch<React.SetStateAction<boolean>>;
}

// Define the EventQr component as a function that accepts EventQrProps
function EventQr({ event, setViewQr }: EventQrProps) {
    const componentRef = useRef<HTMLDivElement>(null);

    return (
        <div>
            <div className='' ref={componentRef}>
                <h1>{event.eventName}</h1>
                <div className='qr-container'>
                    <QRCode value={`http://events-registration-form-poc.s3-website-us-west-2.amazonaws.com/${event.eventId}`} />
                </div>
                <br/> 
                <b>NOTE: Only for day of event use....</b>
                <p>Location: {event.location}</p>
                <p>Date: {event.date}</p>
                <p>Description: {event.description}</p>
            </div>
            <div>
                <ReactToPrint
                    trigger={() => <button className="event-button">Print screen</button>}
                    content={() => componentRef.current}
                />
                <button onClick={() => setViewQr(false)} className="event-button">Close</button>
            </div>
        </div>
    );
}

export default EventQr;
