import React from "react";
import './css/DropDownMenu.css';

type DropdownMenuProps = {
  onLogout: () => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ onLogout }) => {
  return (
    <div className="dropdown-menu">
      <button onClick={onLogout}>Logout</button>
    </div>
  );
};

export default DropdownMenu;
