// src/AddUser.tsx
import React, { useState } from 'react';
import { Event, EventRoleEnum } from './API';
import { Link } from 'react-router-dom';
import { addUserToEvent } from './mutations/processCreateEventUser';
import { CreateEventUserInput } from './API';
import './css/Modal.css'; // Create and import a CSS file for modal styling

interface AddUserProps {
  eventDetails: Event;
  onClose: () => void;
}

const AddUser: React.FC<AddUserProps> = ({ eventDetails, onClose }) => {
  const [email, setEmail] = useState<string>('');

  const handleSubmit = async () => {
    const {
      version, // Exclude version
      __typename, // Exclude __typename
      ...restEventDetails
    } = eventDetails;

    const addUserSubmitData: CreateEventUserInput = {
      ...restEventDetails,
      ownerEmail: email,
      ownerLastName: '',
      eventRole: EventRoleEnum.TEAM,
    };

    try {
      const result = await addUserToEvent(addUserSubmitData);
      console.log('Event user created:', result);
      onClose(); // Close the modal
    } catch (err) {
      console.error('Error creating event user:', err);
    }
  };

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit().catch(err => {
      console.error('Failed to submit form:', err);
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Add User to {eventDetails.eventName}</h2>
        <p>
          Give team members permissions to manage check-ins using this App.
          <br />
          Email must be associated with a valid UCLA LOGON account.
          <br />
          To create an account visit <Link to='https://accounts.iam.ucla.edu/register'>UCLA IAM</Link>.
          <br />
          {/* (Separate multiple email addresses with a comma.) */}
        </p>
        <form onSubmit={onSubmitHandler}>
          <input
            className='search-box'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            placeholder="Enter user email"
            required
          />
          <br />
          <button type="submit" className='event-button'>Invite</button>
        </form>
      </div>
    </div>
  );
};

export default AddUser;