import { gql } from '@apollo/client';
import client from '../apolloClient';
import { updateRegistration } from '../graphql/mutations';
import { UpdateRegistrationInput } from '../API';

export type UpdateRegistrationResponse = {
  registrationId: string;
  eventId: string;
  email?: string | null;
  crmId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  registrationStatus?: string | null;
  qrBase64?: string | null;
  pdfBase64?: string | null;
  pdfUrl?: string | null;
  live?: boolean | null;
  guests?: number | null;
  additionalData?: string | null;
  __typename: string;
};

export async function updateRegistrationDetails(input: UpdateRegistrationInput): Promise<UpdateRegistrationResponse> {
  console.log('updateRegistration input: ', input);
  try {
    const { data, errors } = await client.mutate<{ updateRegistration: UpdateRegistrationResponse }>({
      mutation: gql`${updateRegistration}`,
      variables: { input },
    });

    // Log the entire response
    console.log('GraphQL mutation response:', data);

    if (errors) {
      console.error('GraphQL errors:', errors);
      throw new Error('GraphQL errors occurred');
    }
    if (!data || !data.updateRegistration) {
      throw new Error('No data returned from mutation');
    }

    return data.updateRegistration;
  } catch (err) {
    console.error('Error updating registration:', err);
    throw err;
  }
}