// EventItem.tsx
import { useState } from 'react'
import { Event, Registration } from './API'
import AttendeeList from './AttendeeList'
import ScanTicket from './ScanTicket'
import EventQr from './EventQr'
import { FaVideo, FaUserPlus } from "react-icons/fa";
import { useRouteLoaderData, useLoaderData, useParams, Outlet } from 'react-router-dom'
import NavBar from './NavBar';
import AddUser from './AddUserModal';
import './css/Event.css';

function EventItem() { 
  const events = useRouteLoaderData("events") as Event[];
  const [registrations, setRegistrations] = useState<Registration[]>(useLoaderData() as Registration[]);
  const { eventId } = useParams();
  const [scanTicket, setScanTicket] = useState<boolean>(false);
  const [viewQr, setViewQr] = useState<boolean>(false);
  const [showSelfRegistration, setShowSelfRegistration] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);

  const event = events.find(i => i.eventId === eventId);
  if (typeof eventId === "undefined" || typeof event === "undefined") return "Not Found";

  const {
    eventName,
    location,
    date,
    description
  } = event;

  function startScanningTicket() {
    setScanTicket(!scanTicket);
  }

  function onScanTicket(result: Registration | null) {
    return result;
  }

  const handleSelfRegistrationChange = () => {
    setShowSelfRegistration(!showSelfRegistration);
  };

  const readableDate = new Date(`${date}T00:00`).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  return scanTicket ? (
    <>
      <ScanTicket
        eventId={eventId}
        handler={onScanTicket}
        goBack={() => setScanTicket(false)}
        attendees={registrations}
        setAttendees={setRegistrations}
      />
      <Outlet />
    </>
  ) : viewQr ? (
    <>
      <EventQr
        event={event}
        setViewQr={setViewQr}
      />
      <Outlet />
    </>
  )
    : (
      <div className="event-details">
        <div style={{ display: "flex", alignItems: "center" }}>
          <NavBar eventId={eventId} eventName={eventName} />
        </div>
        <h1 className="event-details-header">
          Event Details for
          <br />
          <b>{eventName}</b>
        </h1>
        <address>{location}</address>
        <div className="event-date">{date}</div>
        <p>{description}</p>
        {registrations.length ? (
          <button
            className='event-button'
            onClick={startScanningTicket}
          >
            <FaVideo className="react-icons" /> Scan Tickets
          </button>
        ) : (
          <button className='event-button' disabled>
            <FaVideo className="react-icons" /> Scan Tickets
          </button>
        )}
        <div className='event-button-container'>
          {(event.eventRole === "OWNER" || event.eventRole === "COOWNER") && (
            <div>
              <button className="event-button" onClick={handleAddUserClick}>
                <FaUserPlus className="react-icons" /> Add Users To Event Team
              </button>
            </div>
          )}

          <div className='self-registration-container'>
            <p className='self-registration-text'>Does this event allow walk ins?</p>
            <input
              className='self-registration-checkbox'
              type="checkbox"
              checked={showSelfRegistration}
              onChange={handleSelfRegistrationChange}
            />
          </div>

          {showSelfRegistration && (
            <>
              <div className='disclaimer-container'>
                <p>
                  * Only to be used for free events. Do not distribute this QR code or URL prior to the event.
                </p>
              </div>
              <button
                className='event-button'
                onClick={() => setViewQr(true)}
              >
                Self Sign in Site QR
              </button>

              <button
                className='event-button'
                onClick={() => window.open(`http://events-registration-form-poc.s3-website-us-west-2.amazonaws.com/${eventId}`, '_blank')}
              >
                Self Sign in Site URL
              </button>
            </>
          )}
        </div>
        <AttendeeList attendees={registrations} setAttendees={setRegistrations} />
        <Outlet />
        {showAddUserModal && (
          <AddUser eventDetails={event} onClose={handleCloseAddUserModal} />
        )}
      </div>
    )
}

export default EventItem;