// EventList.tsx
import { useRouteLoaderData } from 'react-router-dom';
import EventListItem from './EventListItem';
import { Event } from './API';
import './css/CreateEventModal.css';


function EventList() {
  const events = useRouteLoaderData("events") as Event[];

  return (
    <>
      <div className='dashboard-header-container'>
        <h1 className="dashboard-header">Your Managed Events</h1>
      </div>

      {/* sort events list by date */}
      {events?.sort((a, b) => a.date?.localeCompare(b.date)).map((event, key) => {
        if (event && event.live) {
          return <EventListItem event={event} key={key} />
        }
      })}
    </>
  )
}

export default EventList